.icon-container{
    width: 40px;
    height: 40px;
    border: solid 0.1px rgba(128, 128, 128, 0.4);
    border-radius: 30%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:last-child{
        margin-right: 0;
    }
    .icon{
        width: 20px;
        height: 20px;
        position: relative;
        padding: 0;
        margin: 0;
    }
    .notification-number{
        color: white;
        font-size: 8px;
        position: absolute;
        background-color: red;
        padding: 4px 5px;
        border-radius: 50%;
        top: -5px;
        right: -5px;
    }
}