.health-bar{
    margin: 35px 0 25px;
    .bars{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 5px;
        span{
            font-size:  36px;
        }
    }
    .progress-bar{
        margin-top: 10px;
    }
}