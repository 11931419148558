.user-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    // box-shadow: inset -20px 20px 60px #d9d9d9, 
    //         inset 20px -20px 60px #ffffff;
    .profile ,.actions{
        display: flex;
    flex-direction: row;
    }

    .actions{
        align-content: center;
        justify-content: space-between;
    }
}