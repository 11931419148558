@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
.sub-title{
    
    padding: 0;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 400;
}