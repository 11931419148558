.profile{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    .about{
        margin-top: -10px;
        h4{
            margin: 0;
            padding: 0;
        }
        span{
            font-size: 10px;
            position: absolute;
        }
    }
   
    .profile-pic{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 10px 0 0;
    }
    .online{
        background-color: green;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        left: 25px;
        bottom: 0px;
    }
   
}