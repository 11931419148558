.training-bar{
    margin: 30px 0;
}
.training-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    background-color: blue;
    color: white;
    margin-bottom: 20px;
    padding: 15px 20px;
    line-height: 1.5;
    align-items: center;
    h4{
        font-weight: 400;
    }
    p{
        font-size: 10px;
    }
    .time{
        color: black;
        background-color: white;
        font-size: 10px;
        padding: 2px 4px;
        border-radius: 5px;
    }
    .duration{
        font-size: 20px;
    }
}
.training{
    text-align: center;   
}