.health-tracker {
  padding: 5px 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: inset -20px 20px 60px #d9d9d9, inset 20px -20px 60px #ffffff;
}
.label {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;

  .label-type {
    padding: 0 10px;
    font-size: 10px;
    font-weight: 400;
  }
  img {
    height: 26px;
    width: 26px;
  }
}
