@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
.sub-title{
    
    padding: 0;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 400;
}
.progress-bar{width:100%;height:4px;border-radius:2px;background-color:rgba(128,128,128,0.2)}.progress-bar .progress{border-radius:2px;height:100%}

.health-tracker{padding:5px 10px;border-radius:10px;background:#ffffff;box-shadow:inset -20px 20px 60px #d9d9d9, inset 20px -20px 60px #ffffff}.label{display:flex;flex-direction:row;align-items:center}.label .label-type{padding:0 10px;font-size:10px;font-weight:400}.label img{height:26px;width:26px}

.health-bar{margin:35px 0 25px}.health-bar .bars{display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:5px}.health-bar .bars span{font-size:36px}.health-bar .progress-bar{margin-top:10px}

div{border-radius:20px}

.signup{padding:10px 20px;display:flex;flex-direction:row;text-align:center;background-color:rgba(248,168,103,0.2);position:relative;border-radius:20px;z-index:1;margin:10px 0}.signup p{font-size:12px}.signup img{width:60px;height:80px}.signup button{margin-top:10px;padding:7px 7.5px;background-color:#ff9642;border:none;color:white;border-radius:10px}

.icon-container{width:40px;height:40px;border:solid 0.1px rgba(128,128,128,0.4);border-radius:30%;margin-right:10px;display:flex;justify-content:center;align-items:center;position:relative}.icon-container:last-child{margin-right:0}.icon-container .icon{width:20px;height:20px;position:relative;padding:0;margin:0}.icon-container .notification-number{color:white;font-size:8px;position:absolute;background-color:red;padding:4px 5px;border-radius:50%;top:-5px;right:-5px}

.profile{display:flex;flex-direction:row;align-items:center;position:relative}.profile .about{margin-top:-10px}.profile .about h4{margin:0;padding:0}.profile .about span{font-size:10px;position:absolute}.profile .profile-pic{width:50px;height:50px;border-radius:50%;margin:0 10px 0 0}.profile .online{background-color:green;height:10px;width:10px;border-radius:50%;position:absolute;left:25px;bottom:0px}

.trainer-bar{background-color:white}.trainer{margin:10px 0;display:flex;flex-direction:row;align-items:center;justify-content:space-between}

.training-bar{margin:30px 0}.training-info{display:flex;flex-direction:row;justify-content:space-between;background-color:blue;color:white;margin-bottom:20px;padding:15px 20px;line-height:1.5;align-items:center}.training-info h4{font-weight:400}.training-info p{font-size:10px}.training-info .time{color:black;background-color:white;font-size:10px;padding:2px 4px;border-radius:5px}.training-info .duration{font-size:20px}.training{text-align:center}

.user-nav{display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-radius:20px}.user-nav .profile,.user-nav .actions{display:flex;flex-direction:row}.user-nav .actions{align-content:center;justify-content:space-between}

.side-bar{width:18%;margin-left:auto;border-left:solid 0.2px rgba(128,128,128,0.2)}

